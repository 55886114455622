
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ContentfulComponent } from "../utils/ContentfulComponent";

export const NodeContent = ({contentfulData}) =>{
    const options = {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: (node) => {
            console.log(node)
            const { file, title } = node.data.target.fields;
            return <img src={file.url+"?fm=jpg"} alt={title}/>
          },
          [BLOCKS.EMBEDDED_ENTRY]: (node) => {
            console.log(node)
            return <ContentfulComponent contentfulData={node.data.target} />
          }
        }
      };
    return(
        documentToReactComponents(contentfulData,options)
    )
}
