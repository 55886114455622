import { useState,useContext,useEffect,useRef } from "react"
import { CSSTransition } from "react-transition-group";
import { ModalContext } from "../App";
import { ArtImage } from "./ArtImage";
import { ArtInfo } from "./ArtInfo";
import { Modal } from "./Modal";
import "./_art.scss"
export const Art = ({contentfulData}) =>{
    const [modalOpen, setModalOpen] = useState(false);
    const scrollTop = useRef(window.scrollY || (document.documentElement || document.body.parentNode || document.body).scrollTop);
    const value = useContext(ModalContext);
    const ref = useRef();
    const width = Math.round(window.innerWidth * 0.65);
    const handleClick = () =>{
        setModalOpen(!modalOpen)
        value.setModalIsOpen(!modalOpen)
    }
    const disabledScroll = () => {
        const body = document.querySelector('body');
        if(modalOpen){
            scrollTop.current = window.scrollY || (document.documentElement || document.body.parentNode || document.body).scrollTop;
            body.classList.add("no-scroll");
            document.documentElement.classList.add("no-scroll");

        }else{
            body.classList.remove("no-scroll")
            document.documentElement.classList.remove("no-scroll");
            window.requestAnimationFrame(()=>{
                document.documentElement.scrollTop = scrollTop.current
            })
            
        }        
      }
      console.log(contentfulData)
    return(
        <div ref={ref} onClick={handleClick} className="art" tabIndex={0}>
            <span className="art-description">{contentfulData.fields.description}</span>
            <ArtImage width={contentfulData.fields.thumbnail.fields.file.details.image.width} height={contentfulData.fields.thumbnail.fields.file.details.image.height} src={`${contentfulData.fields.thumbnail.fields.file.url}?w=${width}`} alt={contentfulData.fields.description} />
            <CSSTransition onEntered={disabledScroll} onExit={disabledScroll}in={modalOpen} classNames={"modal-open"} timeout={300} unmountOnExit>
                <Modal>
                <div className="wrapper">
                    <ArtImage width={"100%"} height={"100%"} src={contentfulData.fields.image.fields.file.url} alt={contentfulData.fields.description}/>
                    </div>
                    <ArtInfo contentfulFields={contentfulData.fields} />
                </Modal>
            </CSSTransition>
        </div>
    )
}
